import React, { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from './constants/routeConfig';
import LoadingComponent from './common/LoadingComponent/LoadingComponent';
import { AuthProvider, AuthContext, useAuth } from './auth/AuthContext';
import PrivateRoute from './auth/PrivateRoute';
import NotFound from './pages/NotFound';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import AuthenticatedLayout from './auth/AuthenticatedLayout';

function App() {
  const renderRoutes = (routeList) => {
    return routeList.map((route) => (
      route.children ? (
        // Route with nested children
        <Route key={route.path} path={route.path} element={<route.Component />}>
          {renderRoutes(route.children)}
        </Route>
      ) : (
        // Regular route without children
        <Route key={route.path} path={route.path} element={<route.Component />} />
      )
    ));
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingComponent />}>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={ <Login />} />
            <Route path="/register" element={ <Register />} />

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<AuthenticatedLayout />}>
                  {renderRoutes(routes)}
                </Route>
            </Route>

            {/* Redirect to 404 for undefined routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
