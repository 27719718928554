import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/images/worxstreamLogo.svg'
import axios from 'axios';
import { __API_BASEURL__, __AUTH_URL__ } from '../../constants/keyConstants';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState('');
    // const { login } = useAuth();
    // const [token, setToken] = useEffect()

    useEffect(() => {
        setTimeout(() => {
            navigate(__AUTH_URL__, { replace: true })
        }, 1000)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const req = {
            "email": email,
            "password": password,
        }
        //TODO : add login call here
        // const token = '1|AVFKH2PcsrljAoIT9ezscs6xEpJ0Kcyzj4yAkCm273254a71';
        axios.post(`${__API_BASEURL__}/login`, req, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log("login success", response)
        }).catch((error) => {
            console.log("error", error)
            setError(error.response.data.message)
        })
        // localStorage.setItem("token", token)
        // localStorage.getItem("token");
        // login(token);

        const from = location?.state?.from?.pathname || '/';
        location?.state?.from?.pathname && navigate(from, { replace: true });
    };
    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <img src={LogoImg} alt="Invoice Maker" className='m-auto' style={{ height: "8rem", padding: "1rem 0rem" }} />
                {/* <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <p style={{ color: "red" }}>{error}</p>
                                <button type="submit" className="btn btn-primary w-100">Login</button>
                            </form>
                            <a href='/register'>New User? </a>
                        </div>
                    </div>
                </div> */}
                Redirecting ...
            </div>
        </div>
    );
};

export default Login;