import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, ListItemText, Menu, MenuItem } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import Navbar from '../components/Navbar/Navbar';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { __AUTH_URL__ } from '../constants/keyConstants';

const AuthenticatedLayout = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=>{
    localStorage.setItem("tempToken",location.search?.split("=")[1])
    // if(!location.search?.split("=")[1]){
    //   navigate(__AUTH_URL__, { replace: true })
    // }
  },[location])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Navbar />
      <div className='pt-2 pb-1 d-flex align-items-center justify-content-between'>
        <Breadcrumbs />
        <div className='d-flex align-items-center gap-3 pe-4'>
          <Button startIcon={<ChevronLeftRoundedIcon />} onClick={() => navigate(-1)}> Back </Button>
          <Button
            style={{ minWidth: '20px', padding: '0px', margin: '0px' }}
            id="add-button"
            aria-controls={open ? 'add-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<StarBorderRoundedIcon />}
          >
            Quicklinks
          </Button>
          <Menu
            id="add-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'add-button',
            }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemText>Link one</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemText>Link two</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemText>Link three</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default AuthenticatedLayout;