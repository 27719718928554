import React, { useState } from 'react'
import './Navbar.scss'

import LogoImg from '../../assets/images/worxstreamLogo.svg'
import HomeIcon from '../../assets/images/icon-home.svg'
// import ArrowDownIcon from'../../assets/images/arrow-down.svg'
import AddNewIcon from'../../assets/images/icon-add-new.svg'
import CalendarIcon from'../../assets/images/icon-calendar.svg'
import EmailIcon from'../../assets/images/icon-email.svg'
import MapIcon from'../../assets/images/icon-map.svg'
import NotificationsIcon from'../../assets/images/icon-notifications.svg'
import SearchIcon from'../../assets/images/icon-search.svg'
import SettingsIcon from'../../assets/images/icon-settings.svg'
import UploadIcon from'../../assets/images/icon-upload.svg'
import JobCamLogo from'../../assets/images/logo-jobcam.png'
import QuickBooksLogo from'../../assets/images/quickBooks_logo.png'
import TwilioLogo from'../../assets/images/twilio_logo.png'
import UserDummyImg from'../../assets/images/user-dummy.png'
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom'


import JobIcon from '@mui/icons-material/WorkOutlineOutlined';
import EstimateIcon from '@mui/icons-material/RequestQuoteOutlined';
import InvoiceIcon from '@mui/icons-material/ReceiptOutlined';
import SubscriptionIcon from '@mui/icons-material/CardMembershipOutlined';
import SalesProposalIcon from '@mui/icons-material/LocalAtmOutlined';
import ExpensesIcon from '@mui/icons-material/LibraryBooksOutlined';
import PaymentIcon from '@mui/icons-material/AttachMoneyOutlined';
import PaymentLinkIcon from '@mui/icons-material/DatasetLinkedOutlined';

import ContactIcon from '@mui/icons-material/ContactPageOutlined';
import CompanyIcon from '@mui/icons-material/StoreOutlined';
import DealIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import TaskIcon from '@mui/icons-material/TaskOutlined';
import ActivityIcon from '@mui/icons-material/LocalActivityOutlined';

import ContractIcon from '@mui/icons-material/HandshakeOutlined';
import ProjectIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import SubTaskIcon from '@mui/icons-material/TaskOutlined';

import WorkflowIcon from '@mui/icons-material/AccountTreeOutlined';

import CreditReportIcon from '@mui/icons-material/RequestQuoteOutlined';
import ProductReportIcon from '@mui/icons-material/AnalyticsOutlined';
import LeadReportIcon from '@mui/icons-material/MarginOutlined';
import JobReportIcon from '@mui/icons-material/BusinessCenterOutlined';
import EstimateReportIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import InvoiceReportIcon from '@mui/icons-material/MedicalInformationOutlined';

import ProductIcon from '@mui/icons-material/BrandingWatermarkOutlined';
import ServicesIcon from '@mui/icons-material/BuildCircleOutlined';
import ServicesPlanIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import UploadImageDialog from '../UploadImageDialog/UploadImageDialog'
import { __AUTH_URL__ } from '../../constants/keyConstants'

export default function Navbar() {

  const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false);
  const navigate = useNavigate();

  const handleClickOpenUploadImageDialog = () => {
    setOpenUploadImageDialog(true);
  };

  const handleCloseUploadImageDialog = () => {
    setOpenUploadImageDialog(false);
  };


  return (
    <header className="sticky-top bg-white flex-md-nowrap p-0 shadow-sm" >

      <nav className="navbar navbar-expand-lg navbar-wrapper">
        <div className="container-fluid px-3">
          <a className="navbar-brand" href="/">
            <img src={LogoImg} alt="Invoice Maker" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"> <MenuIcon /></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/"><img src={HomeIcon} alt="Home" /><Link to="/"></Link></a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  SALES WorX
                </a>
                <div className="dropdown-menu">
                  <ul>
                    <li><Link className="dropdown-item" to="/sales/job/list"><JobIcon fontSize='small' /> Job</Link></li>
                    <li><Link className="dropdown-item" to="/sales/estimate/list"><EstimateIcon fontSize='small' />Estimate</Link></li>
                    <li><Link className="dropdown-item" > <InvoiceIcon fontSize='small' /> Invoices</Link></li>

                  </ul>
                  <ul>
                    <li><Link className="dropdown-item" ><SubscriptionIcon fontSize='small' /> Subscriptions </Link></li>
                    <li><Link className="dropdown-item" to="/sales/sales-proposal"><SalesProposalIcon fontSize='small' />Sales Proposal</Link></li>
                    <li><Link className="dropdown-item" to="/sales/expenses/list"><ExpensesIcon fontSize='small' /> Expenses</Link></li>

                  </ul>
                  <ul>
                    <li><Link className="dropdown-item" ><PaymentIcon fontSize='small' /> Payments</Link></li>
                    <li><Link className="dropdown-item" ><PaymentLinkIcon fontSize='small' /> Payment Links</Link></li>

                  </ul>
                </div>
              </li>
              {/* <li className="nav-item">
                  <Link className='nav-link' to="/customer/list">Customers</Link>
                </li> */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  CRM WorX
                </a>
                <div className="dropdown-menu">
                  <ul>
                    <li><Link className='dropdown-item' to="/contact/list"><ContactIcon fontSize='small' />Contact</Link></li>
                    <li><Link className="dropdown-item" to="/"><CompanyIcon fontSize='small' /> Company</Link></li>
                    <li><Link className="dropdown-item" to="/crm/deal/list"><DealIcon fontSize='small' />Deal</Link></li>
                    <li><Link className="dropdown-item" to="/"><ActivityIcon fontSize='small' /> Activity</Link></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Project WorX
                </a>
                <div className="dropdown-menu">
                  <ul>
                    <li><Link className="dropdown-item" to="/"><ContractIcon fontSize='small' />Contracts</Link></li>
                    <li><Link className="dropdown-item" to="/worx/projects/list"><ProjectIcon fontSize='small' />Projects</Link></li>
                    <li><Link className="dropdown-item" to="/worx/task/list"><TaskIcon fontSize='small' />Tasks</Link></li>
                    <li><Link className="dropdown-item" to="/masters/ticket/list"><TicketIcon fontSize='small' />Tickets</Link></li>
                    {/* <li><Link className="dropdown-item" to="/"><SubTaskIcon fontSize='small'/>Subtask</Link></li> */}
                  </ul>
                </div>
              </li>
              {/* TODO: GOING TO MOVE THIS MENU ITEMS IN SETTINGS 
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Automations WorX
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/"><WorkflowIcon fontSize='small'/>Workflow</Link></li>
                    </ul>
                  </div>
                </li> 

                 <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Apps
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li> <Link className="dropdown-item" to="/"> <img src={JobCamLogo} alt="JobCam" /> </Link> </li>
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" to="/"> <img src={TwilioLogo} alt="Twilio" /> </Link></li>
                    </ul>
                    <ul>
                      <li><Link className="dropdown-item" to="/"> <img src={QuickBooksLogo} alt="QuickBooks" /> </Link></li>
                    </ul>
                  </div>
                </li> 

                 <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Reporting & Data
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><Link className="dropdown-item" to="/"><CreditReportIcon fontSize='small'/>Credit Report</Link></li>
                      <li><Link className="dropdown-item" to="/"><ProductReportIcon fontSize='small'/>Product Report</Link></li>
                      <li><Link className="dropdown-item" to="/"><LeadReportIcon fontSize='small'/>Lead Report</Link></li>
                      <li><Link className="dropdown-item" to="/"><JobReportIcon fontSize='small'/>Job Report</Link></li>
                      <li><Link className="dropdown-item" to="/"><EstimateReportIcon fontSize='small'/>Estimate Report</Link></li>
                      <li><Link className="dropdown-item" to="/"><InvoiceReportIcon fontSize='small'/>Invoice Report</Link></li>
                    </ul>
                  </div>
                </li> */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Price Book
                </a>
                <div className="dropdown-menu">
                  <ul>
                    <li><Link className="dropdown-item" to="/masters/products/list"><ProductIcon fontSize='small' />Products</Link></li>
                    <li><Link className="dropdown-item" to="/masters/services/list"><ServicesIcon fontSize='small' /> Services</Link></li>
                    <li><Link className="dropdown-item" to="/"><ServicesPlanIcon fontSize='small' />Services Plan</Link></li>

                  </ul>
                </div>
              </li>

            </ul>
            <form className="d-flex position-relative" role="search">
              {/* <input className="form-control me-2 rounded-pill" type="search" placeholder="Search" aria-label="Search"> */}
              <input className="form-control me-2 rounded-pill" type='search' placeholder='' aria-label=''></input>
              <button className="btn btn-outline-secondary border-0 rounded-pill ms-n3 searchIcon" type="button">
                <img src={SearchIcon} alt="Search" />
              </button>
            </form>
            <ul className="navbar-nav right-nav mt-3 mt-lg-0 align-items-start align-items-lg-center">
              {/* <li className="nav-item dropdown profileMenu">
                  <a className="nav-link dropdown-toggle py-0" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <img className="addNewButton" src={AddNewIcon} alt="Send" /> </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><a className="dropdown-item" href="/">Send Estimate</a></li>
                      <li><a className="dropdown-item" href="/">Send Invoice</a></li>
                    </ul>
                  </div>
                </li> */}
              <li className="vDivider"></li>
              <li><Link href="/" onClick={handleClickOpenUploadImageDialog}><img src={UploadIcon} alt="Upload" title="Upload" /></Link></li>

                <li><a href="/"><img src={CalendarIcon} alt="Calender" title="Calender" /></a></li>
                <li><Link to="/xmail"><img src={EmailIcon} alt="Xmail" title="Xmail" /></Link></li>
                {/* <li><a href="/"><img src={MapIcon} alt="Map" title="Map" /></a></li> */}
                <li><Link to="/"><img src={NotificationsIcon} title="Notification" alt="Notification" /></Link></li>
                <li><Link to="/settings"><img src={SettingsIcon} alt="Settings" title="All Settings" /></Link></li>
                <li className="vDivider"></li>
                <li className="nav-item dropdown profileMenu">
                  <a className="nav-link dropdown-toggle py-0" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={UserDummyImg} alt="User" /></a>
                  <div className="dropdown-menu">
                    <ul>
                      <li><a className="dropdown-item" href="/">My Profile</a></li>
                      <li><a className="dropdown-item" href={__AUTH_URL__}>Logout</a></li>
                    </ul>
                  </div>
                  
                </li>
              </ul>
              
            </div>
          </div>
        </nav>
        <UploadImageDialog open={openUploadImageDialog} onClose={handleCloseUploadImageDialog} />
      </header>
  )
}
