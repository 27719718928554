import {
  ACTION,
  AMOUNT,
  CHECKBOX,
  CLONE_ACTION,
  CONTACT_LIST,
  CUSTOMER_LIST,
  DATE,
  DEAL_LIST,
  DELETE_ACTION,
  DOCUMENT,
  DROPDOWN,
  EDIT_ACTION,
  ESTIMATE_LIST,
  ESTIMATE_PREVIEW_GRID,
  EXPENSES_LIST,
  INSTA_JOBS_LIST,
  JOB_LIST,
  LINK,
  LIST,
  LONG_TEXT,
  MEMBER_LIST,
  MENU_LIST,
  NESTED_TEXT,
  NUMBER,
  PRODUCT_LIST,
  PROFILE,
  PROJECT_LIST,
  SAMPLE_TABLE,
  SERVICE_LIST,
  SF_SEER1_SEARCH_RESULT,
  STATUS,
  SUBTASK_LIST,
  TASK_LIST,
  TEMP_TEXT,
  TEXT,
  TICKET_LIST,
  TOGGLE,
  USER_LIST,
  VIEW_ACTION
} from '../../constants/keyConstants';

export default function tableConfig(type) {
  return [
    {
      type: SAMPLE_TABLE,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { textAlign: "left", verticalAlign: "middle" },
        },
        {
          columnIndex: 1,
          columnName: 'Customer Name',
          columnKey: 'customerName',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Email No.',
          columnKey: 'emailNo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Phone',
          columnKey: 'phone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Address',
          columnKey: 'address',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: SF_SEER1_SEARCH_RESULT,
      list: [

        {
          columnIndex: 1,
          columnName: 'AHRI',
          columnKey: 'ahri',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Outdoor',
          columnKey: 'outdoor',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Coil',
          columnKey: 'coil',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Furnace',
          columnKey: 'furnace',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Width',
          columnKey: 'width',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Blower',
          columnKey: 'blower',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          columnName: 'Cost Price',
          columnKey: 'costPrice',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Features',
          columnKey: 'features',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
      ],
    },

    {
      type: INSTA_JOBS_LIST,
      list: [
        {
          columnIndex: 1,
          columnName: 'Job Name',
          columnKey: 'jobName',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Category',
          columnKey: 'category',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Item',
          columnKey: 'item',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Quantity',
          columnKey: 'quantity',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Rate',
          columnKey: 'rate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Include in',
          columnKey: 'includeIn',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Preview',
          columnKey: 'preview',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
      ],
    },

    {
      type: ESTIMATE_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Date',
          columnKey: 'dates',
          isSortable: true,
          isDraggable: false,
          type: DATE,
        },
        {
          columnIndex: 2,
          columnName: 'Estimate No.',
          columnKey: 'numberToShow',
          isSortable: true,
          isDraggable: true,
          type: LINK,
          editActionURL: '/sales/estimate/edit',
        },
        {
          columnIndex: 3,
          columnName: 'Customer Name',
          columnKey: 'customer',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 4,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: STATUS,
        },
        {
          columnIndex: 5,
          columnName: 'Amount',
          columnKey: 'quotationTotal',
          isSortable: true,
          isDraggable: true,
          type: NUMBER,
        },
        {
          columnIndex: 6,
          columnName: 'Documents',
          columnKey: 'documents',
          isSortable: false,
          isDraggable: false,
          type: DOCUMENT,
        },
        {
          columnIndex: 9,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/sales/estimate/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: ESTIMATE_PREVIEW_GRID,
      list: [
        {
          columnIndex: 1,
          columnName: '#',
          columnKey: 'number',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Service/Product',
          columnKey: 'serviceProduct',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: false,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Unit Cost ($)	',
          columnKey: 'unitCost',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Qty',
          columnKey: 'qty',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Total ($)',
          columnKey: 'total',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Tax ($)',
          columnKey: 'tax',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 8,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: CUSTOMER_LIST,
      list: [
        {
          columnIndex: 1,
          columnName: 'Created On',
          columnKey: 'dates',
          isSortable: true,
          isDraggable: false,
          type: DATE,
        },
        {
          columnIndex: 2,
          columnName: 'Customer Name.',
          columnKey: 'companyName',
          isSortable: true,
          isDraggable: true,
          type: PROFILE,
        },
        {
          columnIndex: 3,
          columnName: 'Contact',
          columnKey: 'customer',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 4,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: STATUS,
        },
        {
          columnIndex: 5,
          columnName: 'PO',
          columnKey: 'po',
          isSortable: true,
          isDraggable: true,
          type: TOGGLE,
        },
        {
          columnIndex: 6,
          columnName: 'WH',
          columnKey: 'wh',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 7,
          columnName: 'Order',
          columnKey: 'order',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 8,
          columnName: 'Order No.',
          columnKey: 'orderNo',
          isSortable: false,
          isDraggable: false,
          type: TOGGLE,
        },
        {
          columnIndex: 9,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/customer/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

    {
      type: CONTACT_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Contact ID',
          columnKey: 'contactId',
          isSortable: true,
          isDraggable: false,
          editActionURL: '/contact/edit',
          type: LINK,
        },
        {
          columnIndex: 2,
          columnName: 'Name',
          columnKey: 'displayName',
          isSortable: true,
          isDraggable: false,
          editActionURL: '/contact/edit',
          type: PROFILE,
        },
        {
          columnIndex: 3,
          columnName: 'Email',
          columnKey: 'email',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Phone Number',
          columnKey: 'phone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Contact Owner',
          columnKey: 'contactOwnerName',
          isSortable: true,
          isDraggable: true,
          type: TEXT, // TODO : DROPDOWN
        },
        {
          columnIndex: 6,
          columnName: 'Life Cycle Stage',
          columnKey: 'contactStageName',
          isSortable: true,
          isDraggable: true,
          type: DROPDOWN,
        },
        {
          columnIndex: 7,
          columnName: 'Lead Status',
          columnKey: 'contactStatusName',
          isSortable: true,
          isDraggable: true,
          type: DROPDOWN,
        },
        // {
        //   columnIndex: 8,
        //   columnName: 'Primary Company',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        // {
        //   columnIndex: 9,
        //   columnName: 'Last Activity',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        // {
        //   columnIndex: 10,
        //   columnName: 'Create Date',
        //   columnKey: '',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        {
          columnIndex: 11,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          editActionURL: '/contact/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: PRODUCT_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 3,
          columnName: 'Product Name/Number',
          columnKey: 'name',
          isSortable: true,
          isDraggable: true,
          type: PROFILE,
          editActionURL: '/masters/products/edit',
        },
        {
          columnIndex: 2,
          columnName: 'Product Unit',
          columnKey: 'qty',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
          // cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 4,
          columnName: 'Description',
          columnKey: 'descriptionRtf',
          isSortable: false,
          isDraggable: true,
          type: LONG_TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Product Sales Price',
          columnKey: 'salesPrice',
          isSortable: true,
          isDraggable: true,
          type: AMOUNT,
        },
        {
          columnIndex: 6,
          columnName: 'Cost per Item',
          columnKey: 'costPerItem',
          isSortable: true,
          isDraggable: true,
          type: AMOUNT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION, CLONE_ACTION],
          editActionURL: '/masters/products/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: SERVICE_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 3,
          columnName: 'Service Name/Number',
          columnKey: 'name',
          isSortable: true,
          isDraggable: true,
          type: PROFILE,
          editActionURL: '/masters/services/edit',
        },
        {
          columnIndex: 2,
          columnName: 'Service Unit',
          columnKey: 'qty',
          isSortable: false,
          isDraggable: false,
          type: TEXT,
          // cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 4,
          columnName: 'Description',
          columnKey: 'descriptionRtf',
          isSortable: false,
          isDraggable: true,
          type: LONG_TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Service Sales Price',
          columnKey: 'salesPrice',
          isSortable: true,
          isDraggable: true,
          type: AMOUNT,
        },
        {
          columnIndex: 6,
          columnName: 'Cost per Item',
          columnKey: 'costPerItem',
          isSortable: true,
          isDraggable: true,
          type: AMOUNT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION, CLONE_ACTION],
          editActionURL: '/masters/services/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: JOB_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Date',
          columnKey: 'date',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Job Nunber',
          columnKey: 'jobNumber',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Customer Info',
          columnKey: 'customerInfo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Job Info',
          columnKey: 'jobInfo',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Documents',
          columnKey: 'documents',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: DEAL_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Deal Name',
          columnKey: 'dealName',
          isSortable: true,
          isDraggable: true,
          editActionURL: '/crm/deal/edit',
          type: PROFILE,
        },
        {
          columnIndex: 2,
          columnName: 'Stage',
          columnKey: 'pipeline.stageName',
          isSortable: true,
          isDraggable: true,
          type: NESTED_TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Amount',
          columnKey: 'dealAmount',
          isSortable: true,
          isDraggable: true,
          type: AMOUNT,
        },
        {
          columnIndex: 4,
          columnName: 'Expected Close Date',
          columnKey: 'closeDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Owner',
          columnKey: 'owner',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 7,
          columnName: 'Deal Source',
          columnKey: 'source.label.valueName',
          isSortable: true,
          isDraggable: true,
          type: NESTED_TEXT,
        },
        {
          columnIndex: 8,
          columnName: 'Deal Type',
          columnKey: 'type.label.valueName',
          isSortable: true,
          isDraggable: true,
          type: NESTED_TEXT,
        },
        {
          columnIndex: 9,
          columnName:'Priority',
          columnKey: 'priority',
          isSortable: true,
          isDraggable: true,
          type: TEMP_TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION, CLONE_ACTION, ],
          editActionURL: '/crm/deal/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: PROJECT_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Code',
          columnKey: 'projectCode',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Name',
          columnKey: 'projectName',
          isSortable: true,
          isDraggable: true,
          type: PROFILE,
          editActionURL: '/worx/projects/view',
        },
        // {
        //   columnIndex: 3,
        //   columnName: 'Members',
        //   columnKey: 'members',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        {
          columnIndex: 4,
          columnName: 'Start Date',
          columnKey: 'startDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Deadline',
          columnKey: 'endDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        // {
        //   columnIndex: 6,
        //   columnName: 'Clients',
        //   columnKey: 'clients',
        //   isSortable: true,
        //   isDraggable: true,
        //   type: TEXT,
        // },
        {
          columnIndex: 7,
          columnName: 'Category',
          columnKey: 'category',
          isSortable: true,
          isDraggable: true,
          type: TEMP_TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Department',
          columnKey: 'department',
          isSortable: true,
          isDraggable: true,
          type: TEMP_TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Contact',
          columnKey: 'contact',
          isSortable: true,
          isDraggable: true,
          type: LIST,
        },
        {
          columnIndex: 7,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEMP_TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: true,
          isDraggable: true,
          type: LONG_TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Estimated Hours',
          columnKey: 'estimatedHours',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList: [VIEW_ACTION, DELETE_ACTION, EDIT_ACTION, CLONE_ACTION, MENU_LIST],
          editActionURL: '/worx/projects/edit',
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: TASK_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 9,
          columnName: 'Title',
          columnKey: 'title',
          isSortable: true,
          isDraggable: true,
          editActionURL: "/worx/task/edit",
          type: PROFILE,
        },
        {
          columnIndex: 9,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: true,
          isDraggable: true,
          type: LONG_TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Priority',
          columnKey: 'priority',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Start Date',
          columnKey: 'startDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'End Date',
          columnKey: 'endDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEMP_TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Creation Date',
          columnKey: 'createDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION, CLONE_ACTION],
          editActionURL: "/worx/task/edit",
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: SUBTASK_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 9,
          columnName: 'Task Title',
          columnKey: 'taskTitle',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Subtask Title',
          columnKey: 'title',
          isSortable: true,
          isDraggable: true,
          editActionURL: "/worx/task/edit",
          type: PROFILE,
        },
        {
          columnIndex: 9,
          columnName: 'Description',
          columnKey: 'description',
          isSortable: true,
          isDraggable: true,
          type: LONG_TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Start Date',
          columnKey: 'startDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'End Date',
          columnKey: 'endDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'Create Date',
          columnKey: 'createDate',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION, CLONE_ACTION],
          editActionURL: "/worx/task/edit",
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: EXPENSES_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'ID',
          columnKey: 'id',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Item Name',
          columnKey: 'itemName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Price',
          columnKey: 'price',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Employees',
          columnKey: 'employees',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 5,
          columnName: 'Purchase From',
          columnKey: 'purchaseFrom',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Purchase Date',
          columnKey: 'purchaseDate',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 7,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: TICKET_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'Ticket#',
          columnKey: 'ticket',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Ticket Subject',
          columnKey: 'ticketSubject',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Requester Name',
          columnKey: 'requesterName',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Requested On',
          columnKey: 'requestedOn',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Others',
          columnKey: 'others',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },
    {
      type: MEMBER_LIST,
      list: [
        {
          columnIndex: 0,
          columnName: '#',
          columnKey: 'id',
          type: NUMBER,
          // cellStyle: { width:"20px", padding:"0 0 0 10px", verticalAlign: "middle", textAlign:"center" },
        },
        {
          columnIndex: 1,
          columnName: 'Name',
          columnKey: 'name',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 2,
          columnName: 'Email',
          columnKey: 'email',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Phone',
          columnKey: 'phone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        // {
        //   columnIndex: 4,
        //   type: ACTION,
        //   actionList: [DELETE_ACTION, EDIT_ACTION],
        //   cellStyle: { textAlign: "center", verticalAlign: "middle" },
        // },
      ],
    },
    {
      type: USER_LIST,
      list: [
        {
          columnIndex: 0,
          type: CHECKBOX,
          cellStyle: { width: "20px", padding: "0 0 0 10px", verticalAlign: "middle", textAlign: "center" },
        },
        {
          columnIndex: 1,
          columnName: 'ID',
          columnKey: 'id',
          isSortable: true,
          isDraggable: false,
          type: NUMBER,
        },
        {
          columnIndex: 2,
          columnName: 'User Name',
          columnKey: 'userName',
          isSortable: true,
          isDraggable: false,
          type: TEXT,
        },
        {
          columnIndex: 3,
          columnName: 'Email',
          columnKey: 'email',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 4,
          columnName: 'Roles',
          columnKey: 'roles',
          isSortable: true,
          isDraggable: true,
          type: DATE,
        },
        {
          columnIndex: 5,
          columnName: 'Teams',
          columnKey: 'teams',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 6,
          columnName: 'Status',
          columnKey: 'status',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 7,
          columnName: 'Timezone',
          columnKey: 'timezone',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 8,
          columnName: 'Super Admin',
          columnKey: 'superAdmin',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 9,
          columnName: 'API Access',
          columnKey: 'apiAccess',
          isSortable: true,
          isDraggable: true,
          type: TEXT,
        },
        {
          columnIndex: 10,
          type: ACTION,
          actionList: [DELETE_ACTION, EDIT_ACTION],
          cellStyle: { textAlign: "center", verticalAlign: "middle" },
        },
      ],
    },

  ].find((eachObject) => eachObject.type === type).list;
}
