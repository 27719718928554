// SettingsLayout.js
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import UserVerificationIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ProductIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import PipelineIcon from "@mui/icons-material/CalendarViewWeekOutlined";
import ContactIcon from '@mui/icons-material/ContactPageOutlined';
import DealIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ContractIcon from '@mui/icons-material/HandshakeOutlined';
import ProjectIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import ExpensesIcon from '@mui/icons-material/LibraryBooksOutlined';
import GeneralIcon from "@mui/icons-material/ListAltOutlined";
import SalesProposalIcon from "@mui/icons-material/LocalAtmOutlined";
import UserIcon from "@mui/icons-material/PersonOutlineOutlined";
import InvoiceIcon from '@mui/icons-material/ReceiptOutlined';
import EstimateIcon from '@mui/icons-material/RequestQuoteOutlined';
import TwoFactorAuthIcon from '@mui/icons-material/VerifiedUserOutlined';
import BusinessHoursIcon from '@mui/icons-material/WorkHistoryOutlined';
import JobIcon from '@mui/icons-material/WorkOutlineOutlined';

import "./Settings.scss";

const SettingsLayout = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('/settings/General');
    }
  }, [location.pathname, navigate]);

  const items = [
    { name: "General", icon: <GeneralIcon /> },
    { name: "UserVerification", icon: <UserVerificationIcon /> },
    { name: "TwoFA", icon: <TwoFactorAuthIcon /> },
    { name: "BusinessHours", icon: <BusinessHoursIcon /> },
    { name: "Pipeline", icon: <PipelineIcon /> },
    { name: "User", icon: <UserIcon /> },
    { name: "Job", icon: <JobIcon /> },
    { name: "Estimate", icon: <EstimateIcon /> },
    { name: "Invoice", icon: <InvoiceIcon /> },
    { name: "SalesProposal", icon: <SalesProposalIcon /> },
    { name: "Expenses", icon: <ExpensesIcon /> },
    { name: "Deal", icon: <DealIcon /> },
    { name: "Contact", icon: <ContactIcon /> },
    { name: "Product", icon: <ProductIcon /> },
    { name: "Project", icon: <ProjectIcon /> },
    { name: "Contract", icon: <ContractIcon /> },
   
  ];

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatItemName = (item) => item.replace(/([a-z])([A-Z])/g, '$1 $2');

  return (
    <div className="settingsPage container-fluid">
      <div className="d-flex gap-3 position-relative">
        <div className="leftPanel">
          <div className="white-container pt-1">
            <div className="pb-2">
              <TextField
                placeholder="Search"
                variant="standard"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => {
                const isSelected = location.pathname === `/settings/${item.name}`;
                return (
                  <Box
                    key={item.name}
                    onClick={() => navigate(`/settings/${item.name}`)}
                    className={`settingsNavItem ${isSelected ? "selColor" : ""}`}
                  >
                    {item.icon}
                    <Typography>{formatItemName(item.name)}</Typography>
                  </Box>
                );
              })
            ) : (
              <Typography>No items found</Typography>
            )}
          </div>
        </div>
        <div className="rightPanel">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
