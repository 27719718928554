import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { __AUTH_URL__ } from '../constants/keyConstants';

const PrivateRoute = () => {
  // const { user } = useAuth();
  const location = useLocation();

  if (!localStorage.getItem("token")) {
    return <Navigate to={__AUTH_URL__} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;